import FilterTypes, { Filters } from "../common/consts/filterTypesV2"

const invertMapping = (mapping) => {
  return Object.entries(mapping).reduce((invertedMapping, [key, value]) => {
    invertedMapping[value] = key
    return invertedMapping
  }, {})
}

class AutomationsConditionsTypes {}

AutomationsConditionsTypes.filterOperators = {
  EQUAL: "equal",
  LESS_THAN: "less_than",
  GREATER_THAN: "greater_than",
  BETWEEN: "between",
  NOT_EQUAL: "not_equal",
  INCLUDE: "include",
  NOT_BETWEEN: "not_between",
  IN_ARRAY: "in_array",
  NOT_EXIST: "not_exist",
  IN: "in",
}

AutomationsConditionsTypes.includeFilterOperatorsToConditionOperators = {
  [FilterTypes.filterOperators.EQUAL]: AutomationsConditionsTypes.filterOperators.EQUAL,
  [FilterTypes.filterOperators.NOT_EQUAL]: AutomationsConditionsTypes.filterOperators.NOT_EQUAL,
  [FilterTypes.filterOperators.BETWEEN]: AutomationsConditionsTypes.filterOperators.BETWEEN,
  [FilterTypes.filterOperators.NOT_BETWEEN]: AutomationsConditionsTypes.filterOperators.NOT_BETWEEN,
  [FilterTypes.filterOperators.LESS_THAN]: AutomationsConditionsTypes.filterOperators.LESS_THAN,
  [FilterTypes.filterOperators.MORE_THAN]: AutomationsConditionsTypes.filterOperators.GREATER_THAN,
  [FilterTypes.filterOperators.NOT_EXIST]: AutomationsConditionsTypes.filterOperators.NOT_EXIST,
  [FilterTypes.filterOperators.IN_ARRAY]: AutomationsConditionsTypes.filterOperators.IN_ARRAY,
  [FilterTypes.filterOperators.IN]: AutomationsConditionsTypes.filterOperators.IN,
}

AutomationsConditionsTypes.excludeFilterOperatorsToConditionOperators = {
  [FilterTypes.filterOperators.EQUAL]: AutomationsConditionsTypes.filterOperators.NOT_EQUAL,
  [FilterTypes.filterOperators.BETWEEN]: AutomationsConditionsTypes.filterOperators.NOT_BETWEEN,
}

AutomationsConditionsTypes.conditionOperatorsToIncludeFilterOperators = invertMapping(
  AutomationsConditionsTypes.includeFilterOperatorsToConditionOperators
)

AutomationsConditionsTypes.conditionOperatorsToExcludeFilterOperators = invertMapping(
  AutomationsConditionsTypes.excludeFilterOperatorsToConditionOperators
)

AutomationsConditionsTypes.filterValueTypes = {
  NUMBER: "number",
  DATE: "date",
  STRING: "string",
  ARRAY: "array",
}

AutomationsConditionsTypes.percentageFields = [
  Filters.CAMPAIGN_ROI.fieldName,
  Filters.CAMPAIGN_RESULTS_RATE.fieldName,
  Filters.CAMPAIGN_CTR.fieldName,
  Filters.CAMPAIGN_BOUNCE_RATE.fieldName,
]

AutomationsConditionsTypes.dollarFields = [
  Filters.CAMPAIGN_AVERAGE_CPC.fieldName,
  Filters.CAMPAIGN_VISIT_PROFIT.fieldName,
  Filters.CAMPAIGN_PROFIT.fieldName,
  Filters.CAMPAIGN_CLICK_VALUE.fieldName,
  Filters.CAMPAIGN_VISIT_VALUE.fieldName,
  Filters.CAMPAIGN_VISIT_COST.fieldName,
  Filters.CAMPAIGN_RPM.fieldName,
  Filters.CAMPAIGN_RPM_PAGE_VIEWS.fieldName,
  Filters.CAMPAIGN_DAILY_BUDGET.fieldName,
  Filters.CAMPAIGN_COST_PER_RESULT.fieldName,
  Filters.CAMPAIGN_SPEND.fieldName,
  Filters.CAMPAIGN_VISIT_PROFIT.fieldName,
  Filters.CAMPAIGN_BID.fieldName,
]

AutomationsConditionsTypes.dateFields = [
  Filters.CAMPAIGN_CREATE_DATE.fieldName,
  Filters.CAMPAIGN_LAST_MODIFIED.fieldName,
  Filters.CAMPAIGN_ACTIVATION_DATE.fieldName,
  Filters.CAMPAIGN_INITIAL_ACTIVATION_DATE.fieldName,
]

AutomationsConditionsTypes.filterFieldsByType = {
  [AutomationsConditionsTypes.filterValueTypes.NUMBER]: [
    Filters.CAMPAIGN_CREATED_BY.fieldName,
    Filters.CAMPAIGN_VISITS.fieldName,
    Filters.CAMPAIGN_CLICKS.fieldName,
    Filters.CAMPAIGN_RESULTS.fieldName,
    Filters.CAMPAIGN_RESULTS_RATE.fieldName,
    Filters.CAMPAIGN_AVERAGE_CPC.fieldName,
    Filters.CAMPAIGN_BID.fieldName,
    Filters.CAMPAIGN_VISIT_VALUE.fieldName,
    Filters.CAMPAIGN_VISIT_PROFIT.fieldName,
    Filters.CAMPAIGN_ROI.fieldName,
    Filters.CAMPAIGN_VISIT_ROI.fieldName,
    Filters.CAMPAIGN_PROFIT.fieldName,
    Filters.CAMPAIGN_RPM.fieldName,
    Filters.CAMPAIGN_RPM_PAGE_VIEWS.fieldName,
    Filters.CAMPAIGN_CTR.fieldName,
    Filters.CAMPAIGN_PPS.fieldName,
    Filters.CAMPAIGN_BOUNCE_RATE.fieldName,
    Filters.CAMPAIGN_TOTAL_BUDGET.fieldName,
    Filters.CAMPAIGN_DAILY_BUDGET.fieldName,
    Filters.CAMPAIGN_SPEND.fieldName,
    Filters.CAMPAIGN_CLICK_VALUE.fieldName,
    Filters.CAMPAIGN_VISIT_COST.fieldName,
    Filters.CAMPAIGN_COST_PER_RESULT.fieldName,
    Filters.CAMPAIGN_VISIT_DURATION.fieldName,
  ],
  [AutomationsConditionsTypes.filterValueTypes.DATE]: [
    Filters.CAMPAIGN_CREATE_DATE.fieldName,
    Filters.CAMPAIGN_LAST_MODIFIED.fieldName,
    Filters.CAMPAIGN_ACTIVATION_DATE.fieldName,
    Filters.CAMPAIGN_INITIAL_ACTIVATION_DATE.fieldName,
  ],
}

export default AutomationsConditionsTypes
