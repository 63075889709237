class ArticlesActionTypes {}
ArticlesActionTypes.FETCH_ARTICLES_RESPONSE = "FETCH_ARTICLES_RESPONSE"
ArticlesActionTypes.ARTICLES_LIST_LOADING = "ARTICLES_LIST_LOADING"
ArticlesActionTypes.ROLLBACK_ARTICLES_PAGE = "ROLLBACK_ARTICLES_PAGE"
ArticlesActionTypes.UPDATE_ITEMS_PER_PAGE = "UPDATE_ITEMS_PER_PAGE"
ArticlesActionTypes.RESET_ARTICLES_PAGINATION = "RESET_ARTICLES_PAGINATION"
ArticlesActionTypes.UPDATE_ARTICLES_PAGE = "UPDATE_ARTICLES_PAGE"
ArticlesActionTypes.SHOULD_UPDATE_ARTICLES_PAGE = "SHOULD_UPDATE_ARTICLES_PAGE"
ArticlesActionTypes.UPDATE_FILTER_ARTICLES = "UPDATE_FILTER_ARTICLES"
export default ArticlesActionTypes
