import React from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"

class TimeFrameDropdown extends React.Component {
  constructor(props) {
    super(props)
    const filteredMenuItems = props.isInternalUser
      ? AutomationCreationConsts.TIME_FRAMES
      : AutomationCreationConsts.TIME_FRAMES.filter((item) => item.timeFrame !== "LAST_HOUR")

    this.state = {
      menuOpen: false,
      menuAnchorEl: null,
      menuItems: filteredMenuItems,
      selectedIndexesArr: [0],
    }
  }

  handleMenuOpen = (event) => {
    this.setState({
      menuOpen: true,
      menuAnchorEl: event.currentTarget,
    })
  }

  handleMenuClose = () => {
    this.setState({
      menuOpen: false,
    })
  }

  handleMenuClick = (index) => {
    this.setState({
      selectedIndexesArr: index,
    })

    let selectedTimeFrame = this.state.menuItems[index]
    this.props.handleSelect("selectedTimeFrame", selectedTimeFrame)

    this.handleMenuClose()
  }

  componentDidMount() {
    if (this.props.automationDetailsState.selectedTimeFrame) {
      const selectedIndex = this.state.menuItems.findIndex(
        (item) => item.timeFrame === this.props.automationDetailsState.selectedTimeFrame.timeFrame
      )
      if (selectedIndex !== -1) {
        this.handleMenuClick([selectedIndex])
      }
    }
  }

  render() {
    let buttonClassName = "creation-generic-dropdown-button darker-text "
    let header = "Choose action"
    if (this.state.selectedIndexesArr.length > 0) {
      header = this.state.menuItems[this.state.selectedIndexesArr[0]].name
    }

    return (
      <div className={"text-field"}>
        <div className="creation-generic-dropdown-label">Time Frame</div>
        <GenericDropdown
          header={header}
          selectOptionCallback={(index) => {
            this.handleMenuClick(index)
          }}
          options={this.state.menuItems}
          open={this.state.menuOpen}
          anchorEl={this.state.menuAnchorEl}
          selectedIndexes={this.state.selectedIndexesArr}
          openDropdownCallback={(event) => {
            this.handleMenuOpen(event)
          }}
          allowAutoSuggest={false}
          closeDropdownCallback={this.handleMenuClose}
          allowNoSelection={false}
          buttonClassName={buttonClassName}
          menuClassName={"creation-generic-dropdown-menu"}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isInternalUser: state.app.currentUser.user_roles.some((role) => role.role.is_internal),
  }
}

export default connect(mapStateToProps)(TimeFrameDropdown)
