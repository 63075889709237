import automationsTableConsts from "./automationsTableConsts"
import Consts from "../app/consts"

let AutomationsConsts = {
  LIST_COLUMNS: automationsTableConsts,
  AUTOMATION_STATUS_RUNNING: "ACTIVE",
  AUTOMATION_STATUS_PAUSED: "PAUSED",
  AUTOMATIONS_ERROR_MESSAGE: "There was an error getting the automations data",
  AUTOMATION_ITEMS_PER_PAGE_OPTIONS: [50, 75, 100],
  VALUE_TYPE_PERCENTAGE: "percentage",
  VALUE_TYPE_FIXED: "fixed",

  REPEATING: "REPEATING",
  SPECIFIC_HOURS: "SPECIFIC_HOURS",

  AUTOMATION_OPERATION_INCREASE: "INCREASE",
  AUTOMATION_OPERATION_DECREASE: "DECREASE",

  FREQUENCY_VALUES: {
    HOURLY: "Every hour",
    DAILY: "Once a day",
    THREE_HOURS: "Every 3 hours",
    SIX_HOURS: "Every 6 hours",
    TWELVE_HOURS: "Every 12 hours",
  },

  TIME_FRAME_VALUES: {
    LAST_HOUR: "Last Hour",
    LAST_3_HOURS: "Last 3 Hours",
    TODAY: "So Far Today",
    YESTERDAY: "Yesterday",
    LAST_3_DAYS: "Last 3 Days",
    LAST_7_DAYS: "Last 7 Days",
    LAST_30_DAYS: "Last 30 Days",
    THIS_MONTH: "This Month",
    LAST_MONTH: "Last Month",
  },

  ACTION_STATUS_NAMES: {
    ACTIVE: "Activate",
    PAUSED: "Pause",
  },
}

AutomationsConsts.ACTION_TYPE_VALUE = {
  [Consts.ACTION_TYPE_STATUS]: "Status",
  [Consts.ACTION_TYPE_BID]: "Bid",
  [Consts.ACTION_TYPE_BUDGET]: "Daily Budget",
  [Consts.ACTION_TYPE_SUB_SOURCE_BID]: "Sub-Source: Bid",
  [Consts.ACTION_TYPE_SUB_SOURCE_BLOCK]: "Sub-Source: Block",
}

AutomationsConsts.AUTOMATION_STATUS_NAMES = {
  [AutomationsConsts.AUTOMATION_STATUS_RUNNING]: "Active",
  [AutomationsConsts.AUTOMATION_STATUS_PAUSED]: "Paused",
}

AutomationsConsts.AUTOMATION_VALUE_TYPE_SYMBOL = {
  [AutomationsConsts.VALUE_TYPE_FIXED]: "$",
  [AutomationsConsts.VALUE_TYPE_PERCENTAGE]: "%",
}

Object.freeze(AutomationsConsts)
export default AutomationsConsts
