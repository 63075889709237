import axios from "axios"
import Consts from "../app/consts"
import RequestsService from "./requestsService"

export default class articleService {
  static getAllArticles(networkCode, currentPage, itemsPerPage, isRsoc, filters) {
    return axios.get(Consts.BASE_API_URL + "/api/articles_report", {
      params: {
        cancelToken: RequestsService.cancelToken("articles-request"),
        network_code: networkCode,
        current_page: currentPage,
        items_per_page: itemsPerPage,
        is_rsoc: isRsoc ? 1 : 0,
        filters: filters,
      },
    })
  }
  static getArticlesFiltersResources() {
    return axios.get(Consts.BASE_API_URL + "/api/articles/filters_resources", {
      params: {
        cancelToken: RequestsService.cancelToken("articles-filters-request"),
      },
    })
  }
}
