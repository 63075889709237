import AutomationCreationActionTypes from "./automationCreationActionTypes"
import AutomationsConsts from "../automations/automationsConsts"
import AutomationCreationConsts from "./automationCreationConsts"
import { deserializeConditions } from "../utils/automationUtils"
import { isNullOrUndefined } from "../utils/funcUtils"

export const automationCreationInitialState = {
  isOpen: false,
  isLoading: false,
  isEdit: false,
  isDuplicate: false,
  automationName: "",
  selectedSource: AutomationCreationConsts.SOURCES[0],
  selectedActionType: null,
  actionValue: AutomationCreationConsts.DEFAULT_BID_VALUE,
  selectedValueType: AutomationCreationConsts.VALUE_TYPES[0],
  selectedActionOperation: null,
  actionLimit: null,
  conditions: new Map(),
  selectedTimeFrame: AutomationCreationConsts.TIME_FRAMES[1],
  selectedFrequency: AutomationCreationConsts.FREQUENCIES[0],
  selectedNetwork: null,
  automationStatus: AutomationsConsts.AUTOMATION_STATUS_PAUSED,
  createdBy: null,
  ruleId: null,
  selectedScheduleType: AutomationCreationConsts.SCHEDULE_TYPES[AutomationsConsts.REPEATING],
  selectedSpecificHours: null,
}

function automationCreationReducer(state = automationCreationInitialState, action) {
  if (state === undefined || action === undefined) {
    return []
  }

  switch (action.type) {
    case AutomationCreationActionTypes.OPEN_AUTOMATION_CREATION_POPUP:
      if (action.isEdit || (action.isDuplicate && action.automation)) {
        // Create the base state with isOpen set to true
        let newState = Object.assign({}, state, {
          isOpen: true,
        })

        // Add properties from action.automation if it exists
        if (action.automation) {
          const automationProperties = {
            isEdit: action.isEdit === true,
            isDuplicate: action.isDuplicate === true,
            ruleId: action.automation.id,
            selectedNetwork: { code: action.automation.network_code },
            automationName: action.automation.name,
            automationStatus: action.automation.status,
            createdBy: action.automation.user_id,
            selectedSource: { providerId: action.automation.provider_id },
            selectedActionType: { actionType: action.automation.actionType },
            actionValue: action.automation.actionValue,
            selectedValueType: { valueType: action.automation.valueType },
            conditions: deserializeConditions(action.automation.conditions),
            selectedTimeFrame: { timeFrame: action.automation.time_frame },
            selectedFrequency: { frequency: action.automation.frequency },
            actionLimit: action.automation.actionLimit,
            selectedScheduleType: AutomationCreationConsts.SCHEDULE_TYPES[AutomationsConsts.REPEATING],
            selectedSpecificHours: null,
          }
          const specificHoursCondition = action.automation.conditions?.find(
            (condition) => condition.field === "specific_hours"
          )
          if (!isNullOrUndefined(specificHoursCondition)) {
            automationProperties.selectedScheduleType =
              AutomationCreationConsts.SCHEDULE_TYPES[AutomationsConsts.SPECIFIC_HOURS]
            automationProperties.selectedSpecificHours = specificHoursCondition.value
          }

          // Conditionally assign properties to newState only if they exist in action.automation
          Object.keys(automationProperties).forEach((key) => {
            const value = automationProperties[key]
            if (value !== undefined) {
              newState[key] = value
            }
          })
        }

        return newState
      }

      return Object.assign({}, state, {
        isOpen: true,
      })

    case AutomationCreationActionTypes.CLOSE_AUTOMATION_CREATION_POPUP:
      return automationCreationInitialState

    default:
      return state
  }
}

export { automationCreationReducer }
